const cards = [
   {
      id: "img01",
      target: "",
      alt: "Aparador Madeira Demolição",
      title: "Aparador Madeira Demolição",
      description: "",
   },
   {
      id: "img02",
      target: "",
      alt: "Mesa Redonda de Vidro",
      title: "Mesa Redonda de Vidro",
      description: "",
   },
   {
      id: "img03",
      target: "",
      alt: "Conjunto Mesa",
      title: "Conjunto Mesa",
      description: "",
   },
   {
      id: "img04",
      target: "",
      alt: "Conjunto de Mesa Retangular",
      title: "Conjunto de Mesa Retangular",
      description: "",
   },
   {
      id: "img05",
      target: "",
      alt: "Conjunto Flórida",
      title: "Conjunto Flórida",
      description: "",
   },
   {
      id: "img06",
      target: "",
      alt: "Conjunto Garden",
      title: "Conjunto Garden",
      description: "",
   },
   {
      id: "img07",
      target: "",
      alt: "Conjunto Ipanema",
      title: "Conjunto Ipanema",
      description: "",
   },
   {
      id: "img08",
      target: "",
      alt: "Conjunto Mônaco",
      title: "Conjunto Mônaco",
      description: "",
   },
   {
      id: "img09",
      target: "",
      alt: "Conjunto Paris em Madeira",
      title: "Conjunto Paris em Madeira",
      description: "",
   },
   {
      id: "img10",
      target: "",
      alt: "Conjunto Requinte",
      title: "Conjunto Requinte",
      description: "",
   },
   {
      id: "img11",
      target: "",
      alt: "Conjunto Veneza Caldeirão",
      title: "Conjunto Veneza Caldeirão",
      description: "",
   },
   {
      id: "img12",
      target: "",
      alt: "Conjunto Viena",
      title: "Conjunto Viena",
      description: "",
   },
   {
      id: "img13",
      target: "",
      alt: "Jogo de mesa Bistro Redonda-060-com-2-banquetas-alta-natural",
      title: "Jogo de mesa Bistro Redonda",
      description: "Diâmetro 60cm. Acompanha duas banquetas alta natural",
   },
   {
      id: "img14",
      target: "",
      alt: "Mesa Angra 1",
      title: "Mesa Angra 1",
      description: "",
   },
   {
      id: "img15",
      target: "",
      alt: "Mesa Armação",
      title: "Mesa Armação",
      description: "",
   },
   {
      id: "img16",
      target: "",
      alt: "Mesa de Centro Demolição",
      title: "Mesa de Centro Demolição",
      description: "",
   },
   {
      id: "img17",
      target: "",
      alt: "Mesa de centro Kodus",
      title: "Mesa de centro Kodus",
      description: "",
   },
   {
      id: "img18",
      target: "",
      alt: "Mesa de apoio",
      title: "Mesa de apoio",
      description: "",
   },
   {
      id: "img19",
      target: "",
      alt: "Mesa de centro Paris",
      title: "Mesa de centro Paris",
      description: "",
   },
   {
      id: "img20",
      target: "",
      alt: "Mesa de centro",
      title: "Mesa de centro",
      description: "",
   },
   {
      id: "img21",
      target: "",
      alt: "Mesa de jantar Angra",
      title: "Mesa de jantar Angra",
      description: "",
   },
   {
      id: "img22",
      target: "",
      alt: "Mesa e banco Viena",
      title: "Mesa e banco Viena",
      description: "",
   },
   {
      id: "img23",
      target: "",
      alt: "Mesa e cadeiras - Área Gourmet",
      title: "Mesa e cadeiras - Área Gourmet",
      description: "",
   },
   {
      id: "img24",
      target: "",
      alt: "Mesa Floripa",
      title: "Mesa Floripa",
      description: "",
   },
   {
      id: "img25",
      target: "",
      alt: "Mesa Gênova",
      title: "Mesa Gênova",
      description: "",
   },
   {
      id: "img27",
      target: "",
      alt: "Mesa Maison Colmeia",
      title: "Mesa Maison Colmeia",
      description: "",
   },
   {
      id: "img28",
      target: "",
      alt: "Mesa Paris",
      title: "Mesa Paris",
      description: "",
   },
   {
      id: "img29",
      target: "",
      alt: "Mesa Peroba",
      title: "Mesa Peroba",
      description: "",
   },
   {
      id: "img30",
      target: "",
      alt: "Mesa Patchwork",
      title: "Mesa Patchwork",
      description: "",
   },
   {
      id: "img31",
      target: "",
      alt: "Mesa Requinte - Tampo trançado",
      title: "Mesa Requinte",
      description: "Tampo trançado",
   },
   {
      id: "img32",
      target: "",
      alt: "Mesa Riade",
      title: "Mesa Riade",
      description: "",
   },
   {
      id: "img33",
      target: "",
      alt: "Mesa Canela com bancos canela Cadeira Capri",
      title: "Mesa Canela com bancos canela Cadeira Capri",
      description: "",
   },
   {
      id: "img34",
      target: "",
      alt: "Mesa de centro Dormente-070m-móveis-rústicos",
      title: "Mesa de centro Dormente",
      description: "Diâmetro 70cm. Móveis Rústicos",
   },
   {
      id: "img35",
      target: "",
      alt: "Mesa de centro Patchwork color",
      title: "Mesa de centro Patchwork color",
      description: "Medidas: 60cm x 60cm",
   },
   {
      id: "img36",
      target: "",
      alt: "Mesa de jantar PDL Rústica",
      title: "Mesa de jantar PDL Rústica",
      description: "Medidas: 220cm x 100cm",
   },
   {
      id: "img37",
      target: "",
      alt: "Mesa de jantar Patchwork Natural",
      title: "Mesa de jantar Patchwork Natural",
      description: "Medidas: 120cm x 80cm",
   },
   {
      id: "img38",
      target: "",
      alt: "Mesa de jantar Rústica Redonda Peroba rosa",
      title: "Mesa de jantar Rústica Redonda Peroba rosa",
      description: "Diâmetro: 160cm",
   },
   {
      id: "img39",
      target: "",
      alt: "Mesa de jantar Topo natural 1",
      title: "Mesa de jantar Topo natural 1",
      description: "Medidas: 190cm x 80cm",
   },
   {
      id: "img40",
      target: "",
      alt: "Mesa de jantar Topo natural 2",
      title: "Mesa de jantar Topo natural 2",
      description: "Medidas: 160cm x 80cm",
   },
   {
      id: "img41",
      target: "",
      alt: "Mesa prancha Jatobá Móveis rústicos",
      title: "Mesa prancha Jatobá Móveis rústicos",
      description: "Medidas: 320cm x 77cm",
   },
   {
      id: "img42",
      target: "",
      alt: "Mesa redonda Tower-e-cadeiras-paris-artefama-com-cristaleira",
      title: "Mesa redonda Tower",
      description: "Acompanha cadeiras Paris Artefama e Cristaleira",
   },
   {
      id: "img43",
      target: "",
      alt: "Mesa Nevada",
      title: "Mesa Nevada",
      description: "",
   },
   {
      id: "img44",
      target: "",
      alt: "Mesa de Jantar Recanto",
      title: "Mesa de Jantar Recanto",
      description: "Domingo Arquitetura e Design",
   },
]

export { cards }